<template>
  <div id="contents" class="user">
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="openTabIndex"
          :commit="'setOpenTabIndexToUserPage'"
          :options="[
            { title: '조회&수정', name: 'modification' },
            { title: '등록', name: 'new' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <a
            class="btn_admin"
            :class="{ on: managementMode, active: selectedIndex !== -1 }"
            v-show="selectedIndex != -1 && openTabIndex == 0"
            @click="toggleManagement"
            href="#"
            >관리</a
          >

          <button class="btn_admin" @click="CloseThisPage">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            :class="{ active: index == openTabIndex }"
            v-for="(subTab, index) in subTabs"
            :key="subTab.name"
          >
            <a @click="setOpenTabIndex(index)">{{ subTab.title }}</a>
          </li>
        </ul>
      </div>
      <!-- form section -->
      <user-modification-form v-if="openTabIndex == 0"></user-modification-form>
      <user-new-form v-if="openTabIndex == 1"></user-new-form>
    </div>
  </div>
</template>

<script>
import UserModificationForm from '@/views/forms/System/UserModificationForm.vue';
import UserNewForm from '@/views/forms/System/UserNewForm.vue';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import { mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    UserModificationForm,
    UserNewForm,
    AsideSelectric,
  },
  computed: {
    ...mapGetters({
      subTabs: 'getSubTabsFromUserPage',
      openTabIndex: 'getOpenTabIndexFromUserPage',
      managementMode: 'getManagementModeFromUserPage',
      selectedIndex: 'getTableSelectedIndexFromUserPage',
    }),
  },
  methods: {
    ...mapMutations({
      setOpenTabIndex: 'setOpenTabIndexToUserPage',
      toggleManagementMode: 'toggleManagementModeToUserPage',
    }),
    toggleManagement() {
      if (this.selectedIndex != -1) {
        this.toggleManagementMode();
      }
    },
  },
  created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitUserPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style scoped></style>
