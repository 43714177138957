<template>
  <div class="article">
    <div class="tbl_wrap user_list">
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <colgroup>
            <col v-for="(n, index) in 4" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <!-- <th>id</th> -->
              <th>이름</th>
              <th>아이디</th>
              <th>메일주소</th>
              <th>권한</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(user, index) in users"
              :key="user.id"
              :class="selectedIndex == index ? 'active' : ''"
              @click="selectRow(index)"
            >
              <!-- <td>{{ user.id }}</td> -->
              <td>{{ user.name }}</td>
              <td>{{ user.account }}</td>
              <td>{{ user.email }}</td>
              <td>
                {{ getRoleTypeName(user.user_role_type_id) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode">
      <div class="form user_form" @submit.prevent autocomplete="false">
        <form autocomplete="false">
          <div class="input_text">
            <label>이름</label>
            <input
              type="text"
              id="name"
              placeholder="내용을 입력하세요"
              :disabled="!managementMode"
              v-model="managementData.name"
            />
          </div>
          <div class="input_text">
            <label>아이디</label>
            <input
              autocomplete="false"
              type="text"
              id="id"
              placeholder="내용을 입력하세요"
              disabled
              v-model="managementData.account"
            />
          </div>
          <div class="input_text">
            <label>이메일</label>
            <input
              type="text"
              id="email"
              placeholder="내용을 입력하세요"
              :disabled="!managementMode"
              v-model="managementData.email"
            />
          </div>
          <div class="input_text">
            <label>등록일시</label>
            <input
              type="text"
              readonly
              :value="managementData.create_time || '1970-01-01' | formatDate"
            />
          </div>
          <div class="input_text">
            <label>최근접속 시간</label>
            <input
              type="text"
              readonly
              :value="managementData.login_time || '1970-01-01' | formatDate"
            />
          </div>
          <div
            class="input_text"
            :class="managementMode ? 'input_permission' : ''"
          >
            <label>권한</label>

            <input
              v-show="!managementMode"
              type="text"
              id="permission"
              :value="getRoleTypeName(managementData.user_role_type_id)"
              readonly
            />
            <button
              v-show="
                !managementMode &&
                  managementData.user_role_type_id != -1 &&
                  managementData.user_role_type_id ==
                    getUserRole.find(x => x.name == '일반사용자').id
              "
              type="button"
              class="btn_sub2"
              @click="goToAuth()"
            >
              권한관리
            </button>
            <div v-show="managementMode">
              <my-selectric
                :id="`UserRole-selectric`"
                :watch="managementData.user_role_type_id"
                :options="getUserRoleOptions"
                :state="selectedIndex == -1 ? 'disabled' : ''"
                :commit="'setManageUserRoleTypeIdToUserPage'"
                :key="reRend"
                v-if="showSelectric && selectedIndex != -1"
                @refresh="changeKey($event)"
              >
              </my-selectric>
            </div>
          </div>
          <div class="input_text">
            <label>사용상태</label>
            <input
              v-show="!managementMode"
              type="text"
              readonly
              :value="getStateTypeName(managementData.user_state_type_id)"
            />
            <div v-show="managementMode">
              <my-selectric
                :id="'UserState-selectric'"
                :watch="managementData.user_state_type_id"
                :options="stateTypeOptions"
                :state="selectedIndex == -1 ? 'disabled' : ''"
                :commit="'setManageUserStateTypeIdToUserPage'"
                :key="reRend2"
                v-if="showSelectric && selectedIndex != -1"
                @refresh="changeKey($event)"
              >
              </my-selectric>
            </div>
          </div>
          <template v-if="managementMode">
            <div class="input_text">
              <label>비밀번호 수정</label>
              <input
                autocomplete="false"
                type="password"
                placeholder="비밀번호 수정"
                v-model="password"
              />
            </div>
            <div class="input_text">
              <label>비밀번호 확인</label>
              <input
                autocomplete="false"
                type="password"
                placeholder="비밀번호 확인"
                v-model="password_confirm"
              />
            </div>
            <button
              class="btn_sub2"
              :disabled="isValidModify && chkPasswordNull"
              @click="submitForm"
            >
              저장
            </button>
            <div class="delete_user">
              <button @click.prevent="ShowModal"><span></span>계정삭제</button>
            </div>
            <div class="reset_password">
              <button @click.prevent="show_reset_modal = true">
                <span></span>비밀번호 초기화
              </button>
            </div>
          </template>
        </form>
      </div>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      @OnYesClick="deleteUser"
      @onclose="CloseModal"
    ></two-button-modal>
    <two-button-modal
      :modal_title="'비밀번호 초기화'"
      :modal_content="'사용자의 비밀번호를 정말로 초기화 하시겠습니까?'"
      :isModalOpen="show_reset_modal"
      @OnYesClick="
        resetPassword();
        show_reset_modal = false;
      "
      @onclose="show_reset_modal = false"
    ></two-button-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ModalMixin from '@/mixins/modal';
import fetchMixin from '@/mixins/fetch';
import SpinnerMixin from '@/mixins/spinner';
import routes from '@/routes/routes';
import TwoButtonModal from '@/layouts/components/TwoButtonModal.vue';
import MySelectric from '@/layouts/components/MySelectric';
import crypto from 'crypto';
export default {
  mixins: [ModalMixin, SpinnerMixin, fetchMixin],
  components: {
    MySelectric,
    TwoButtonModal,
  },
  data() {
    return {
      stateTypeOptions: [
        {
          label: '사용',
          value: 1,
        },
        {
          label: '사용안함',
          value: 2,
        },
      ],
      reRend: 0,
      reRend2: 0,
      showSelectric: false,
      password: '',
      password_confirm: '',

      //modal
      my_modal_title: '',
      my_modal_content: '',
      my_isModalOpen: false,

      show_reset_modal: false,
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromUserPage',
      usersSrc: 'getUsersFromUserPage',
      getUserRole: 'getUserRoleTypeFromUserPage',
      getUserRoleOptions: 'getUserRoleTypeOptionsFromUserPage',
      selectedIndex: 'getTableSelectedIndexFromUserPage',
      managementData: 'getManagementDataFromUserPage',
      myAccount: 'getUserId',
      favoriteList: 'getFavoriteFromFavoriteMenuPage',
    }),
    users() {
      if (this.usersSrc.length < 1) {
        return [];
      } else {
        return this.lodash
          .clonedeep(this.usersSrc)
          .filter(x => x.account != 'developer@');
      }
    },

    filterDataList() {
      if (this.favoriteList.length > 0) {
        const mapFilterList = this.favoriteList.map(x => x.sub_menu);
        const filterList = mapFilterList.reduce((a, b) => a.concat(b));

        return filterList;
      } else {
        return false;
      }
    },
    isValidModify() {
      if (this.managementMode && this.selectedIndex != -1) {
        const modifyData = JSON.stringify(this.managementData);
        const originData = JSON.stringify(this.users[this.selectedIndex]);

        if (modifyData == originData) {
          return true;
        } else return false;
      } else return false;
    },
    chkPasswordNull() {
      if (this.password != '' && this.password_confirm != '') {
        return false;
      } else return true;
    },
  },
  methods: {
    ...mapMutations({
      setSelectedIndex: 'setSelectedIndexToUserPage',
      toggleManagementMode: 'toggleManagementModeToUserPage',
    }),
    ...mapActions({
      UPDATE_USER: 'UPDATE_USER',
      REMOVE_USER: 'REMOVE_USER',
      // FetchUserState
    }),
    async goToAuth() {
      const goingPage = routes[0].children.find(x => x.path == '/system/auth');
      if (goingPage != undefined) {
        goingPage.meta.select = this.lodash.clonedeep(this.managementData);
        await this.$router.push('/system/auth');
      }
    },

    changeKey() {
      this.reRend += 1;
      this.reRend2 += 1;
    },
    async fetchUsersAll() {
      try {
        this.showSpinner();
        await this.$store
          .dispatch('FETCH_USER_LIST')
          .then(() => {})
          .catch(error => {
            console.log('@@@@@', error);
            this.openOneButtonModal(
              '불러오기 오류',
              '사용자 정보를 불러오는 중 오류가 발생하였습니다. 다시 로그인하여주십시오.',
            );
          });

        this.hideSpinner();
      } catch (error) {
        console.log('@@@@@', error);
        this.openOneButtonModal(
          '불러오기 오류',
          '사용자 정보를 불러오는 중 오류가 발생하였습니다. 다시 로그인하여주십시오.',
        );
        this.hideSpinner();
      }
    },
    async fetchUserRoleAll() {
      try {
        this.showSpinner();
        this.$store
          .dispatch('FETCH_USER_ROLE_TYPE')
          .then(() => {})
          .catch(() => {
            this.openOneButtonModal(
              '불러오기 오류',
              '사용자 권한을 불러오는 중 오류가 발생하였습니다. 다시 로그인하여주십시오.',
            );
          });
        this.hideSpinner();
      } catch (error) {
        this.hideSpinner();
      }
    },
    selectRow(index) {
      this.setSelectedIndex(index);
      this.$store.commit(
        'setManagementDataToUserPage',
        this.lodash.clonedeep(this.users[this.selectedIndex]),
      );
      this.password = '';
      this.password_confirm = '';
    },
    getRoleTypeName(id) {
      if (this.getUserRole.find(x => x.id == id) == undefined) return '';
      else return this.getUserRole.find(x => x.id == id).name;
    },
    getStateTypeName(id) {
      if (this.stateTypeOptions.find(x => x.value == id) == undefined)
        return '선택';
      else return this.stateTypeOptions.find(x => x.value == id).label;
    },
    hash(password) {
      return crypto
        .createHmac('sha256', process.env.VUE_APP_SECRET_KEY)
        .update(password)
        .digest('hex');
    },

    async submitForm() {
      if (this.managementData.name.trim() == '') {
        this.openOneButtonModal(
          '이름을 확인하여주십시오.',
          '이름은 필수 입력항목입니다.',
        );
        return;
      } else if (this.managementData.user_state_type_id == '') {
        this.openOneButtonModal(
          '사용상태를 확인하여주십시오.',
          '사용 혹은 사용안함을 선택하여주십시오.',
        );
        return;
      }
      let isChangePassword = false;
      if (!this.chkPasswordNull) {
        if (!this.password_valid()) {
          this.openOneButtonModal(
            '비밀번호를 확인하여주십시오.',
            '비밀번호가 서로 다릅니다.',
          );
          return;
        } else if (!this.checkPassword()) {
          this.openOneButtonModal(
            '비밀번호 작성규칙 위반',
            '비밀번호는 8자리이상 15자리 이하로 영문,숫자,특수문자가 반드시 포함되어야 합니다.<br />(사용가능 특수문자 : @$!%*#?&)',
          );
          return;
        } else if (!this.continuity() || !this.specialChar()) {
          this.openOneButtonModal(
            '비밀번호 작성규칙 위반',
            '동일한 문자나 숫자를 3번이상 연속하여 등록할 수 없습니다.',
          );
          return;
        } else {
          isChangePassword = true;
        }
      }

      if (isChangePassword) {
        const hashedPassword = this.hash(this.password);
        const updateData = {
          id: this.managementData.id,
          account: this.managementData.account,
          email: this.managementData.email.trim(),
          password: hashedPassword,
          name: this.managementData.name.trim(),
          user_role_type_id: this.managementData.user_role_type_id,
          user_state_type_id: this.managementData.user_state_type_id,
        };
        console.log(updateData);
        // return;
        this.UpdateUser(updateData);
      } else {
        const updateData = {
          id: this.managementData.id,
          account: this.managementData.account,
          email: this.managementData.email.trim(),
          name: this.managementData.name.trim(),
          user_role_type_id: this.managementData.user_role_type_id,
          user_state_type_id: this.managementData.user_state_type_id,
        };
        console.log(updateData);
        // return;
        this.UpdateUser(updateData);
      }
    },
    checkPassword() {
      let check = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,15}$/;

      if (check.test(this.password)) {
        return true;
      }
    },
    continuity() {
      let regexNo = /(\w)\1\1/;
      if (!regexNo.test(this.password)) {
        return true;
      }
    },
    specialChar() {
      // eslint-disable-next-line
      let sChar = /([\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"])\1\1/;
      if (!sChar.test(this.password)) {
        return true;
      }
    },
    password_valid() {
      // console.log(this.password);
      // console.log(this.password_confirm);
      return this.password == this.password_confirm;
    },
    checkAccount() {
      this.$store
        .dispatch('CHECK_ACCOUNT', { account: this.oldId })
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    resetPassword() {
      const newRandomPassword = this.randomPassword();
      const newHashPassword = this.hash(newRandomPassword);
      this.showSpinner();
      this.$store
        .dispatch('RESET_PASSWORD', {
          id: this.managementData.id,
          password: newHashPassword,
          reset_password: true,
        })
        .then(() => {
          this.openOneButtonModal(
            '비밀번호 재설정',
            `[임시비밀번호: ${newRandomPassword}]<br/>${this.managementData.account}의 비밀번호가 <br/>임시비밀번호로 변경되었습니다. <br/>재로그인시 비밀번호를 수정해 주세요.`,
          );
        })
        .catch(() => {
          this.openOneButtonModal(
            '비밀번호 재설정',
            '초기화 중 오류가 발생하였습니다.',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    randomPassword() {
      let length = 4;
      let result = '';

      for (let i = 0; i < length; i++) {
        let rnd = Math.round(Math.random() * 62);
        if (rnd < 10) {
          result += rnd;
        } else if (rnd > 35) {
          result += String.fromCharCode(rnd + 61);
        } else {
          result += String.fromCharCode(rnd + 55);
        }
      }
      return result;
    },
    async UpdateUser(payload) {
      console.log(payload);
      try {
        this.showSpinner();
        this.$store
          .dispatch('UPDATE_USER', payload)
          .then(() => {
            this.$store
              .dispatch('INSERT_USER_AUTH', {
                user_id: payload.id,
                data_list: this.filterDataList,
              })
              .then(() => {
                this.openOneButtonModal(
                  '수정 성공',
                  '성공적으로 수정되었습니다.',
                );
                this.fetchUsersAll();
                this.toggleManagementMode();
              })
              .finally(() => {});
          })
          .catch(() => {
            this.openOneButtonModal(
              '수정 오류발생',
              '사용자 수정 중 오류가 발생하였습니다. 다시 로그인해주십시오.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      } catch (error) {
        this.openOneButtonModal(
          '수정 오류발생',
          '사용자 수정 중 오류가 발생하였습니다. 다시 로그인해주십시오.',
        );
        this.hideSpinner();
      }
    },
    async deleteUser() {
      this.CloseModal();
      if (this.myAccount == this.managementData.account) {
        this.openOneButtonModal(
          '삭제 불가',
          '현재 로그인중인 계정은 삭제할 수 없습니다.',
        );
        return;
      }
      try {
        this.showSpinner();
        this.$store
          .dispatch('REMOVE_USER', this.managementData.id)
          .then(() => {
            this.fetchUsersAll();
            this.toggleManagementMode();
          })
          .catch(() => {
            this.openOneButtonModal(
              '삭제 오류',
              '사용자 삭제 중 오류가 발생하였습니다. 다시 로그인해주십시오.',
            );
          });
        this.hideSpinner();
      } catch (error) {
        console.log(error);
        this.openOneButtonModal(
          '삭제 오류',
          '사용자 삭제 중 오류가 발생하였습니다. 다시 로그인해주십시오.',
        );
        this.hideSpinner();
      }
    },
    ShowModal() {
      this.my_modal_title = '경고';
      this.my_modal_content = `[${this.managementData.account} 계정삭제] 삭제된 데이터는 복구할 수 없습니다. 정말로 삭제하시겠습니까 ?`;
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
  },
  async created() {
    if (this.users.length == 0) {
      await this.fetchUsersAll();
    }
    if (this.getUserRole.length == 0) {
      await this.fetchUserRoleAll();
    }
    if (this.favoriteList.length == 0) {
      console.log(this.users);
      console.log(this.myAccount);
      if (this.users.id != undefined) {
        const findId = this.users.find(x => x.account == this.myAccount).id;
        await this.FETCH_WITH_PAYLOAD(
          'FETCH_FAVORITE_MENU',
          findId,
          '메뉴 정보',
        );
      } else {
        return '';
      }
    }
    this.showSelectric = true;
  },
};
</script>

<style scoped></style>
