<template>
  <div class="article">
    <div
      class="user_enrollment_mode aside_management_mode"
      @submit.prevent="submitForm"
    >
      <div class="form user_form">
        <form>
          <div class="input_text">
            <label>이름</label>
            <input
              type="text"
              id="name"
              placeholder="내용을 입력하세요"
              v-model="newData.name"
            />
          </div>
          <div class="input_text">
            <label>아이디</label>
            <input
              autocomplete="off"
              type="text"
              id="id"
              placeholder="내용을 입력하세요"
              v-model="newData.account"
            />
          </div>
          <div class="input_text">
            <label>이메일</label>
            <input
              type="text"
              id="email"
              placeholder="내용을 입력하세요"
              v-model="newData.email"
            />
          </div>
          <div class="input_text">
            <label>권한</label>
            <div>
              <my-selectric
                :id="`UserRole-selectric2`"
                :watch="newData.user_role_type_id"
                :options="getUserRoleOptions"
                :commit="'setNewUserRoleTypeIdToUserPage'"
                :key="reRend"
                @refresh="changeKey($event)"
              >
              </my-selectric>
            </div>
          </div>
          <div class="input_text">
            <label>비밀번호 입력</label>
            <input
              id="pwd"
              autocomplete="off"
              type="password"
              placeholder="비밀번호 입력"
              v-model="newData.password"
            />
          </div>
          <div class="input_text">
            <label>비밀번호 재입력</label>
            <input
              id="repwd"
              autocomplete="off"
              type="password"
              placeholder="비밀번호 재입력"
              v-model="password_confirm"
            />
          </div>
          <div class="btn_wrap">
            <button class="btn_sub2">
              등록
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ModalMixin from '@/mixins/modal';
import fetchMixin from '@/mixins/fetch';
import SpinnerMixin from '@/mixins/spinner';
import MySelectric from '@/layouts/components/MySelectric';
import { mapGetters } from 'vuex';
import crypto from 'crypto';

export default {
  mixins: [ModalMixin, SpinnerMixin, fetchMixin],
  components: {
    MySelectric,
  },
  data() {
    return {
      reRend: 0,
      showSelectric: false,
      password_confirm: '',
    };
  },
  computed: {
    ...mapGetters({
      getUserRole: 'getUserRoleTypeFromUserPage',
      getUserRoleOptions: 'getUserRoleTypeOptionsFromUserPage',
      newData: 'getNewDataFromUserPage',
      favoriteList: 'getFavoriteFromFavoriteMenuPage',
    }),
    filterDataList() {
      if (this.favoriteList.length > 0) {
        const mapFilterList = this.favoriteList.map(x => x.sub_menu);
        const filterList = mapFilterList.reduce((a, b) => a.concat(b));
        return filterList;
      } else {
        return false;
      }
    },
  },
  methods: {
    changeKey() {
      this.reRend += 1;
    },
    getRoleTypeName(id) {
      if (this.getUserRole.find(x => x.id == id) == undefined) return '';
      else return this.getUserRole.find(x => x.id == id).name;
    },
    getStateTypeName(id) {
      if (this.stateTypeOptions.find(x => x.value == id) == undefined)
        return '선택';
      else return this.stateTypeOptions.find(x => x.value == id).label;
    },

    async submitForm() {
      if (this.newData.name.trim() == '') {
        this.openOneButtonModal(
          '이름을 확인하여주십시오.',
          '이름은 필수 입력항목입니다.',
        );
        return;
      } else if (this.newData.account.trim() == '') {
        this.openOneButtonModal(
          'ID를 확인하여주십시오.',
          'ID는 필수 입력항목입니다.',
        );
        return;
      } else if (this.newData.password == '') {
        this.openOneButtonModal(
          '비밀번호를 확인하여주십시오.',
          '비밀번호는 필수 입력항목입니다.',
        );
        return;
      } else if (!this.checkPassword()) {
        this.openOneButtonModal(
          '비밀번호 작성규칙 위반',
          '비밀번호는 8자리이상 15자리 이하로 영문,숫자,특수문자가 반드시 포함되어야 합니다.<br />(사용가능 특수문자 : @$!%*#?&)',
        );
        return;
      } else if (!this.continuity() || !this.specialChar()) {
        this.openOneButtonModal(
          '비밀번호 작성규칙 위반',
          '동일한 문자나 숫자를 3번이상 연속하여 등록할 수 없습니다.',
        );
        return;
      } else if (!this.password_valid()) {
        this.openOneButtonModal(
          '비밀번호를 확인하여주십시오.',
          '비밀번호가 서로 다릅니다.',
        );
        return;
      } else {
        this.showSpinner();
        this.$store
          .dispatch('CHECK_ACCOUNT', { account: this.newData.account.trim() })
          .then(() => {
            this.AddUser();
          })
          .catch(() => {
            this.openOneButtonModal(
              '아이디 중복',
              '입력한 아이디가 중복입니다. 다시 확인하여주십시오.',
            );
          });
        this.hideSpinner();
      }
    },
    checkPassword() {
      let check = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,15}$/;

      if (check.test(this.newData.password)) {
        return true;
      }
    },
    continuity() {
      let regexNo = /(\w)\1\1/;

      if (!regexNo.test(this.newData.password)) {
        return true;
      }
    },
    specialChar() {
      // eslint-disable-next-line
      let sChar = /([\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"])\1\1/;
      if (!sChar.test(this.newData.password)) {
        return true;
      }
    },
    password_valid() {
      return this.newData.password == this.password_confirm;
    },
    async fetchUsersAll() {
      try {
        this.showSpinner();
        this.$store
          .dispatch('FETCH_USER_LIST')
          .then(() => {})
          .catch(error => {
            this.openOneButtonModal(
              '불러오기 오류',
              '사용자 정보를 불러오는 중 오류가 발생하였습니다. 다시 로그인하여주십시오.',
            );
            console.log('ERROR', error);
          });

        this.hideSpinner();
      } catch (error) {
        this.hideSpinner();
      }
    },
    hash(password) {
      return crypto
        .createHmac('sha256', process.env.VUE_APP_SECRET_KEY)
        .update(password)
        .digest('hex');
    },
    async AddUser() {
      try {
        const payload = this.lodash.clonedeep(this.newData);
        this.showSpinner();
        payload.user_state_type_id = 1;
        payload.account = payload.account.trim();
        payload.name = payload.name.trim();
        payload.email = payload.email.trim();
        payload.password = this.hash(payload.password);
        await this.$store
          .dispatch('ADD_USER', payload)
          .then(response => {
            let clone = this.lodash.clonedeep(this.filterDataList);
            clone.forEach(el => {
              el.user_id = response.data.data.id;
            });
            if (
              payload.user_role_type_id !==
              this.getUserRole.find(x => x.name == '관리자').id
            ) {
              const about_colab = this.lodash.clonedeep(
                clone.find(x => x.sub_detail == '/system/about'),
              );
              if (about_colab != undefined) {
                clone = clone.filter(x => !x.sub_detail.includes('/system'));
                clone.push(about_colab);
              } else {
                console.log('clone is empty');
              }
            }

            this.$store
              .dispatch('INSERT_USER_AUTH', {
                user_id: response.data.data.id,
                data_list: clone,
              })
              .then(async () => {
                this.openOneButtonModal(
                  '등록 성공',
                  '신규 사용자가 성공적으로 등록되었습니다.',
                );
                await this.fetchUsersAll();
                this.$store.commit('clearNewDataToUserPage');
                this.password_confirm = '';
              })
              .catch(error => {
                this.openOneButtonModal(
                  '등록 오류',
                  '사용자 등록 중 오류가 발생하였습니다. 다시 로그인하여주십시오.',
                );
                console.log('ERROR', error);
              })
              .finally(() => {});
          })
          .catch(error => {
            console.log('ERROR', error);
            this.openOneButtonModal(
              '등록 오류',
              '사용자 등록 중 오류가 발생하였습니다. 다시 로그인하여주십시오.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      } catch (error) {
        console.log('ERROR', error);
        this.openOneButtonModal(
          '등록 오류',
          '사용자 등록 중 오류가 발생하였습니다. 다시 로그인하여주십시오.',
        );
        this.hideSpinner();
      }
    },
  },
  async created() {
    if (this.favoriteList.length < 1) {
      await this.FETCH_FAVORITE_MENU();
    }
    if (this.newData.user_role_type_id == -1) {
      this.newData.user_role_type_id = this.getUserRole.find(
        x => x.name == '일반사용자',
      ).id;
    }
    this.showSelectric = true;
  },
};
</script>

<style scoped></style>
